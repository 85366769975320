import { shared } from './shared'

export const prod = {
  environmentName: 'production',
  baseUrl: 'https://app.tellow.nl/',
  production: true,
  verboseDebugOutput: false,
  deployTestScreens: false,
  firebase: {
    apiKey: 'AIzaSyDMRu4H7VBspXRcu41bcArp7-JGxUlZtEE',
    authDomain: 'hellotellow-production.firebaseapp.com',
    databaseURL: 'https://hellotellow-production-compleet.firebaseio.com',
    projectId: 'hellotellow-production',
    storageBucket: '',
    messagingSenderId: '292583634329',
    appId: '1:292583634329:web:14b671e500ff415e',
    measurementId: '195078132',
    vapidKey: 'BMYarQciKvGG-iIAGIFUa09-_XBq1DVclW6d4yH4MGqYpnbokqPWH4dwjWFCbACPUEDyLQrph2FMRKL8RpZHBd8',
  },
  intercomAppId: 'xrg98po3',
  apiUrl: 'https://api.tellow.nl',
  kvkApiUrl: 'https://kvk-api-dot-tellow-backend-prod.appspot.com',
  billingServiceApiUrl: 'https://billing.tellow.nl/api',
  stripeAntiCancellationCoupon: 'BLIJFBIJTELLOW',
  taxServiceApiUrl: 'https://tax.tellow.nl/api',
  bankServiceApiUrl: 'https://bank.tellow.nl/api',
  segmentApiKey: 'UxKZYudRdiWp4FED80fwbWoFGtwfy4cb',
  userflowToken: 'ct_aq7zy4jqqzf2vmhk5stwlbzrxm',
  paymentsServiceApiUrl: 'https://payments.tellow.nl/api',
  agerasFinanceAppUrl: 'https://app.agerasfinance.com',
  integrationsServiceApiUrl: 'https://integrations.tellow.nl',
  swanBankingUrl: 'https://banking.swan.io/projects/e252852c-61e4-4615-aff9-a549af776912',
  laravelServiceApiUrl: 'https://api2.tellow.nl',
  recaptchaSiteKey: '6LduDj8pAAAAABp4gAOr8RLgobZdGxQD1VF-hO7b',
  ...shared,
}
